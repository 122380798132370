import React from "react";
import About from "../../component/section-about/about";
import Info from "../../component/section-info-slider/info";





function HomePage() {
    return (
        <>
            <About />
            <Info />
        </>
    );
}
export default HomePage;