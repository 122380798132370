import React from "react";
import './about-company.scss';
import Images from '../image/abou-image.jpg';




function AboutCompany() {
    return (
        <section className="section about-us">
            <div className="container about-us__container">
                <div className="about-us__content">
                    <h1 className="about-us__title">
                        Про дитячий центр <span>Inter-Kids</span>
                    </h1>
                    <p className="about-us__sub-title">
                        <span>Inter-Kids</span> — це дитячий садок, який створений для того, щоб забезпечити дітям безпечне, радісне та навчальне середовище. Ми пропонуємо сучасні освітні програми, які допомагають дітям розвивати свої творчі здібності, соціальні навички та інтелект. Наша мета — забезпечити кожній дитині найкращий старт у житті, сприяючи їхньому всебічному розвитку і підготовці до школи. Вихователі "Inter-Kids" працюють з любов'ю і відданістю, створюючи атмосферу, де кожна дитина відчуває себе улюбленою і підтриманою.
                    </p>
                </div>
                <div>

                </div>
            </div>

        </section>
    )
}

export default AboutCompany;