import { React, useState } from "react";
import './pop-up-tel.scss';
import Button from "../button/button";
import CrosshairImf from "../image/modal-crosshair.png";

function PopUpTel() {
    const [phone, setPhone] = useState('');
    const [show, setShow] = useState(false);

    // Функція для відображення/приховування модального вікна
    const ShowText = () => {
        setShow(!show);
    }

    // Закриття модального вікна
    const handleClose = () => {
        setShow(false);
    }

    // Обробка відправки даних до Google Форми
    const handleSubmit = (e) => {
        e.preventDefault();
        
        // Створення форми для відправки даних
        const form = document.createElement('form');
        form.action = 'https://docs.google.com/forms/d/e/1FAIpQLSeNdLoIk4pe-Qdl495p1L9pNHFYhy1GIQRUHPuAhf7uliX5Nw/formResponse';
        form.method = 'POST';
        form.target = 'hidden_iframe';

        // Додаємо поле з номером телефону
        const phoneField = document.createElement('input');
        phoneField.type = 'hidden';
        phoneField.name = 'entry.361302695';  // ID поля телефону у Google Формі
        phoneField.value = phone;
        form.appendChild(phoneField);

        // Додаємо два інші поля зі значенням "*"
        const nameField = document.createElement('input');
        nameField.type = 'hidden';
        nameField.name = 'entry.512631380';  // ID для імені у Google Формі
        nameField.value = '***';
        form.appendChild(nameField);

        const cityField = document.createElement('input');
        cityField.type = 'hidden';
        cityField.name = 'entry.510362471';  // ID для міста у Google Формі
        cityField.value = '***';
        form.appendChild(cityField);

        document.body.appendChild(form);
        
        // Відправка форми
        form.submit();

        // Затримка перед видаленням форми
        setTimeout(() => {
            document.body.removeChild(form);
        }, 2000);  // Чекаємо 2 секунди для того, щоб форма встигла відправитися

        // Повідомлення про успішну відправку
        alert('Ваш номер телефону було відправлено!');
        setPhone('');  // Очищуємо поле вводу
        handleClose();  // Закриваємо модальне вікно
    };

    return (
        <>
            <div className={`modal__overlay ${show ? 'active' : ''}`}></div>
            <div className="content">
                <button onClick={ShowText}>Відкрити форму</button>
            </div>
            <div className="content-text">
                Хочете, зателефонуємо Вам?
            </div>
            {show &&
                <div className="modal__window-call">
                    <form className="modal__form" onSubmit={handleSubmit}>
                        <p>
                            Залиште номер телефону, і ми з вами зв'яжемося!
                        </p>
                        <div>
                            <input
                                type="tel"
                                name="inp-tel"
                                id="inp-tel"
                                placeholder="Ваш номер телефону"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </div>
                        <div className="modal__window-btn">
                            <div onClick={handleClose} className="modal__window-close">
                                <img src={CrosshairImf} alt="Закрити вікно" />
                            </div>

                            <Button
                                classNameBtn={'modal__window-btn'}
                                textContentBtn={'Зателефонуйте мені на цей номер'}
                            />
                        </div>
                    </form>

                </div>
            }
            <iframe name="hidden_iframe" style={{ display: 'none' }}></iframe>
        </>
    )
}

export default PopUpTel;
