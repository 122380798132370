import React from "react";
import ForParentsComponent from "../../component/section-for-parents/forParents";



function ForParents() {
    return (
        <ForParentsComponent />
    )
}

export default ForParents; 