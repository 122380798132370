import React from "react";
import Program from "../../component/section-our-program/program";

function ProgramSchool() {
    return (
        <Program />
    )

}

export default ProgramSchool;