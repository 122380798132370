// src/component/section-job/job.jsx

import React from "react";
import './job.scss';
import Button from '../button/button.jsx';

function JobComponent() {
    return (
        <section className="section job">
            <div className="container job__container">
                <h3 className="job__title">
                    Компанія <span>Inter-Web SoftTech</span>
                </h3>
                <div className="job__content-wrap">
                    <p className="job__intro">
                        Компанія Inter-Web SoftTech запрошує усіх бажаючих замовити собі сучасні, вишукані та функціональні сайти. Наші сайти відзначаються передовими технологіями, унікальним дизайном та високою продуктивністю. Замовивши сайт у нас, ви отримаєте:
                    </p>
                    <ul className="job__benefits">
                        <li>Сучасний дизайн, що приваблює клієнтів.</li>
                        <li>Високу швидкість завантаження та продуктивність.</li>
                        <li>Адаптивний інтерфейс для будь-яких пристроїв.</li>
                        <li>Легке управління контентом та оновлення.</li>
                        <li>Оптимізацію для пошукових систем (SEO).</li>
                        <li>Інтеграцію з соціальними мережами та іншими платформами.</li>
                        <li>Професійна підтримка та консультації.</li>
                    </ul>
                    <p>
                        Замовивши сайт у компанії Inter-Web SoftTech, ви отримуєте інструмент для ефективного ведення бізнесу та залучення нових клієнтів. Ми гарантуємо якість, індивідуальний підхід та своєчасне виконання робіт.
                    </p>
                    <p className="job__additional-offer">
                        Для бажаючих ми пропонуємо повний цикл послуг: від розробки до розміщення в Інтернеті на нашому хостингу за оптимальними цінами.
                    </p>
                </div>
                {/*<div className="job__examples">
                    <h3 className="job__examples-title">Приклади</h3>
                    <div className="job__examples-list">
                        {Array.from({ length: 10 }, (_, i) => (
                            <div key={i} className="job__example">
                                <h4>Приклад {i + 1}</h4>
                                <p>Опис прикладу {i + 1}</p>
                                <a href="#">Посилання на приклад {i + 1}</a>
                            </div>
                        ))}
                    </div>
                </div>*/}
               
            </div>
        </section>
    );
}

export default JobComponent;
