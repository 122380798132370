import React from "react";
import Image from '../image/study.jpeg';
import './studeis.scss';





function Studies() {
    return (
        <section className="section studies">
            <div className="container studies__container ">
                <h2>ПЕРЕВАГИ НАШОГО ДИТЯЧОГО ЦЕНТРУ</h2>
                <div className="studies__content">
                    <div className="studies__quality">
                        <h4>
                        Всебічний розвиток
                        </h4>
                        <p>
                        Ми забезпечуємо умови для гармонійного розвитку дитини, включаючи творчі, інтелектуальні та фізичні активності, що сприяють її зростанню.
                        </p>

                    </div>
                    <div className="studies__approach">
                        <h4>
                            Індивідуальний підхід
                        </h4>
                        <p>
                        У нашому центрі кожна дитина отримує індивідуальну увагу. Ми підлаштовуємо заняття під потреби та інтереси дитини, щоб максимально розкрити її потенціал.
                        </p>
                    </div>
                    <div className="studies__location">
                        <h4>
                            Зручне розташування
                        </h4>
                        <p>
                        Наш дитячий центр зручно розташований, що полегшує щоденний доїзд та створює комфортні умови для відвідування занять.
                        </p>

                    </div>
                    <div className="studies__feature">
                        <h4>
                            Сучасне обладнання та методи навчання
                        </h4>
                        <p>
                        Ми використовуємо новітні методики розвитку дітей та сучасне обладнання, щоб зробити процес навчання цікавим та ефективним.
                        </p>
                    </div>

                    <div className="studies__development">
                        <h4>
                        Розвиток соціальних та емоційних навичок
                        </h4>
                        <p>
                        Ми допомагаємо дітям розвивати навички спілкування, співпраці та емоційної стійкості, що є важливим для їхнього майбутнього життя.
                        </p>

                    </div>
                    <div className="studies__save">
                        <h4>
                        Безпечне та підтримуюче середовище
                        </h4>
                        <p>
                        Наш центр створює атмосферу безпеки та підтримки, де кожна дитина може розвиватися та навчатися у комфортних умовах.
                        </p>
                    </div>

                </div>

            </div>

        </section>
    )


}

export default Studies;