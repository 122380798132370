import { React, useState } from "react";
import Header from "../header/header";
import Footer from "../footer/footer"
import './for-parents.scss';
import Crosshair from '../image/modal-crosshair.png';


function ForParents() {
    const [rotate, setRotate] = useState(false);
    const [rotate1, setRotate1] = useState(false);
    const [rotate2, setRotate2] = useState(false);
    const [rotate3, setRotate3] = useState(false);
    const [rotate4, setRotate4] = useState(false);
    const [rotate5, setRotate5] = useState(false);

    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);

    const openClick = () => {
        setOpen(!open)
        setRotate(!rotate)
    };

    const openClick1 = () => {
        setOpen1(!open1)
        setRotate1(!rotate1)
    };

    const openClick2 = () => {
        setOpen2(!open2)
        setRotate2(!rotate2)
    };

    const openClick3 = () => {
        setOpen3(!open3)
        setRotate3(!rotate3)
    };

    const openClick4 = () => {
        setOpen4(!open4)
        setRotate4(!rotate4)
    };

    const openClick5 = () => {
        setOpen5(!open5)
        setRotate5(!rotate5)
    };




    return (
        <section className="our-program" >
            <div className="container our-program__container ">
                <h3 className="title">
                    ВІДПОВІДІ НА <span>НАЙПОШИРЕНІШІ ЗАПИТАННЯ</span>
                </h3>
                <div className="our-program__tab">
                    <div className="tab-content">
                        <div onClick={openClick} className="tab-text">
                            <p className="tab-title">
                            Як проходить адаптація дитини в дитячому центрі?
                            </p>
                            <div className={`tabs-crasshair ${rotate ? 'rotate' : ''}`}>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div className={`tabs-content-hide ${open ? 'visible' : ''}`}>
                            <p>Адаптація дитини в нашому центрі відбувається поступово. Перші кілька днів ми створюємо максимально комфортні умови для малюка, щоб він міг звикнути до нового середовища. Батьки можуть бути присутніми під час адаптаційних занять, щоб допомогти дитині почуватися впевненіше. Поступово дитина починає брати участь у всіх активностях самостійно.</p>
                        </div>


                    </div>
                    <div className="tab-content">
                        <div onClick={openClick1} className="tab-text">
                            <p className="tab-title">
                            Які програми розвитку пропонує ваш центр?
                            </p>
                            <div className={`tabs-crasshair ${rotate1 ? 'rotate' : ''}`}>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div className={`tabs-content-hide ${open1 ? 'visible' : ''}`}>
                        <p>Наш центр пропонує різноманітні програми розвитку для дітей від 2 до 7 років. Ми зосереджені на всебічному розвитку дитини через гру, творчість, фізичні активності та раннє навчання. Програми включають заняття з розвитку мовлення, логіки, математики а також соціальних навичок.</p>
                        </div>

                    </div>
                    <div className="tab-content">
                        <div onClick={openClick3} className="tab-text">
                            <p className="tab-title">
                            Як організовані ігрові та навчальні зони в центрі?
                            </p>
                            <div className={`tabs-crasshair ${rotate3 ? 'rotate' : ''}`}>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div className={`tabs-content-hide ${open3 ? 'visible' : ''}`}>
                        <p>У нашому центрі передбачені окремі зони для активних ігор, творчих занять та навчання. Ігрові зони обладнані безпечними матеріалами та іграшками, що сприяють фізичному і когнітивному розвитку дітей. Навчальні зони оснащені сучасними матеріалами для розвитку логіки, мовлення та творчості. Ми створюємо комфортне середовище, де діти можуть одночасно вчитися та гратися, що сприяє всебічному розвитку.</p>
                        </div>

                    </div>
                    <div className="tab-content">
                        <div onClick={openClick4} className="tab-text">
                            <p className="tab-title">
                            Які заходи безпеки передбачені?
                            </p>
                            <div className={`tabs-crasshair ${rotate4 ? 'rotate' : ''}`}>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div className={`tabs-content-hide ${open4 ? 'visible' : ''}`}>
                        <p>У нашому центрі ми дотримуємося всіх необхідних заходів безпеки для забезпечення комфорту і захисту дітей. Центр обладнаний камерами відеоспостереження, входи та виходи контролюються, а всі співробітники проходять регулярні медичні огляди та навчання з надання першої допомоги. У разі оголошення повітряної тривоги діти відразу знаходяться в безпечному цокольному приміщення, що забезпечує додатковий рівень захисту та спокій для батьків. Ми дбаємо про те, щоб кожен маленький вихованець був у безпеці в будь-якій ситуації.</p>
                        </div>

                    </div>
                    <div className="tab-content">
                        <div onClick={openClick5} className="tab-text">
                            <p className="tab-title">
                            Які заняття проводяться в центрі?
                            </p>
                            <div className={`tabs-crasshair ${rotate5 ? 'rotate' : ''}`}>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div className={`tabs-content-hide ${open5 ? 'visible' : ''}`}>
                        <p>У нашому центрі діти займаються різноманітними активностями, які сприяють їхньому всебічному розвитку. Це ігри на свіжому повітрі, творчі заняття з малювання, англійської мови, основ IT, а також рухливі ігри, що розвивають координацію та фізичну активність. Окрім цього, проводяться тематичні заняття, спрямовані на розвиток соціальних та комунікативних навичок.</p>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )


};

export default ForParents;


