import React from "react";
import AboutCompany from "../../component/about-company/about-company";
import Studies from "../../component/studies/studeis";

function AboutUs() {
    return (
        <>
            <AboutCompany />
            <Studies />
        </>
    )
}
export default AboutUs;


