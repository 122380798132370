import React from "react";
import Prjcts from "../../component/section-prjct/prjct";


function Projects() {
    return (
        <Prjcts />
    )
}

export default Projects;