import ImageHtml from "../image/html.png";
import ImageCss from "../image/css.png";
import ImageJS from "../image/js.png";
import ImagePy from "../image/py.png";
import ImageMySql from "../image/mysqllogo.png";
import ImageRoblox from "../image/roblox.jpg";
import User1 from "../image/Mogilko-768x512.jpg";
import User2 from "../image/Odrinskiy-768x535.jpg";
import User3 from "../image/sofa-682x1024.jpg";
import UserFeedback from "../image/001.jpg";
import UserFeedback2 from "../image/002.jpg";
import UserFeedback3 from "../image/003.jpg";
import UserFeedback4 from "../image/004.jpg";
import UserFeedback5 from "../image/005.jpg";
import UserFeedback6 from "../image/006.jpg";
import UserFeedback7 from "../image/007.jpg";
import UserFeedback8 from "../image/008.jpg";
import UserFeedback9 from "../image/009.jpg";
import UserFeedback10 from "../image/010.jpg";
import UserFeedback11 from "../image/011.jpg";
import UserFeedback12 from "../image/012.jpg";
//import UserFeedback13 from "../image/sssssss.jpg";
//import UserFeedback14 from "../image/aa.jpg";
//import UserFeedback15 from "../image/aaa.jpg";
//import UserFeedback16 from "../image/aaaaa.jpg";
//import UserFeedback17 from "../image/assass.jpg";
export const data = [
  {
    id: 1,
    title: "Творчі заняття",
    discriotion:
      "Наші творчі заняття включають малювання, ліплення з пластиліну, аплікацію та багато іншого. Це допомагає дітям розвивати свої художні здібності та уяву.",
    image: ImageRoblox,
  },
  {
   
    id: 2,
    title: "IT напрямок",
    discriotion:
      "Наш айті напрямок допомагає дітям з раннього віку зануритися у світ технологій та програмування. Діти вчаться основам роботи з комп'ютером, логічному мисленню та командній роботі.",
    image: ImageHtml,  
},
  {
    id: 3,
    title: "Розвиваючі ігри",
    discriotion:
      "Розвиваючі ігри сприяють покращенню когнітивних навичок, а вправи для мілкої моторики допомагають дітям розвивати координацію та навички рук. Діти вчаться працювати з дрібними предметами, що сприяє розвитку уважності та концентрації.",
    image: ImageCss,
  },
  {
    id: 4,
    title: "Заняття на свіжому повітрі",
    discriotion:
    "Ми приділяємо велику увагу фізичному розвитку дітей. На свіжому повітрі проводимо рухливі ігри, спортивні заняття та екскурсії.",
    image: ImageJS,
  },
  // {
   //  id: 5,
    // age: "Advanced PHP&MySQL",
   //  title: "Senior",
   //  discriotion:
   //    "Senior рік – це вивчення продвинутого рівня PHP та роботи з базами даних MySQL. PHP – це потужна мова програмування, призначена спеціально для розробки веб-додатків. На цьому етапі курсу ми поглиблюємо знання PHP, фокусуючись на складніших техніках та практиках, які використовуються у професійній веб-розробці. Крім того, учні вивчають роботу з MySQL – найпопулярнішою системою управління базами даних для веб-додатків. Курс рекомендовано: 7-11 клас.",
  //   image: ImagePy,
 //  },
  
  //{
  //  id: 5,
  //  age: "6-9 клас",
  //  title: "Roblox",
  //  discriotion:
  //    "В айті - школі ми досліджуємо світ програмування за допомогою Роблокса! Тут ти зможеш створити власні ігри, додавати веселі функції та навіть розробляти свої власні історії.Забудь про границі - на Роблоксі можна втілити будь - які ідеї та зробити своє імя в світі розробників. Готуйся до захоплюючого подорожі у світ програмування!",
  //  image: ImageRoblox,
  //},
];

export const dataStudents = [
  {
    id: 1,
    Name: "Денис",
    Image: User1,
    Discriotion:
      "Мені 15 років і я живу і вчуся в місті Красноград, зараз активно вивчаю веб-програмування, працюю з мовою текстової розмітки HTML & CSS, створюю сайти за допомогою мови JS.",
  },

  {
    id: 2,
    Name: "Богдан",
    Image: User2,
    Discriotion:
      "Мені 15 років я живу і вчуся в місті Краснограді. Захоплююся вивченням мов програмування. Працюю і створюю сайти за допомогою мови текстової розмітки HTML & CSS і JS. Готовий взяти замовлення на розробку сайту.",
  },

  {
    id: 3,
    Name: "Софія",
    Image: User3,
    Discriotion:
      "Я опанувала HTML та такі мови програмування, як: JavaScript, CSS – почала вивчати Python. Вмію користуватися бібліотекою jQueri. Мої хоббі: програмування та малювання.",
  },
];

export const dataFeedBack = [
  {
    Name: "Анна, 34 роки:",
    Discriotion:
      '"Дитячий садок Inter-Kids - це місце, де моя донька завжди щаслива. Вона з нетерпінням чекає кожного нового дня, щоб повернутися до улюблених вихователів та друзів. Ми дуже задоволені програмою та турботою, яку тут отримує кожна дитина."',
    Avatar: UserFeedback,
  },
  {
    Name: "Михайло, 36 років:",
    Discriotion:
      '"Мій син відвідує садок Inter-Kids вже другий рік. Вихователі тут дійсно дбають про дітей, створюють теплу і дружню атмосферу. Він навчився спілкуватися з іншими дітьми, став більш самостійним і впевненим у собі."',
    Avatar: UserFeedback2,
  },
  {
    Name: "Олена, 39 років:",
    Discriotion:
      '"Inter-Kids - це не просто садок, це другий дім для моєї дитини. Завдяки заняттям з творчості та музики, вона стала більш відкритою і соціальною. Дякую вихователям за турботу і професіоналізм!"',
    Avatar: UserFeedback3,
  },
  {
    Name: "Віктор, 35 років:",
    Discriotion:
      '"Мій син завжди з радістю йде в садок Inter-Kids. Тут чудова команда вихователів, які не тільки піклуються, але й допомагають дітям розвиватися у всіх сферах. Рекомендую цей садок усім батькам!"',
    Avatar: UserFeedback4,
  },
  {
    Name: "Світлана, 30 років:",
    Discriotion:
      '"Донька в захваті від занять у садочку Inter-Kids. Вона навчилася малювати, співати, брати участь у різних іграх. Вона вже має багато друзів, і це дійсно робить її щасливою. Вдячна всім вихователям за їхню роботу!"',
    Avatar: UserFeedback5,
  },
  {
    Name: "Юрій, 38 років:",
    Discriotion:
      '"Inter-Kids - це найкращий вибір для нашої родини. Син почав розвивати свої комунікативні навички, а також став більш організованим та дисциплінованим. Ми дуже вдячні вихователям за їхню роботу."',
    Avatar: UserFeedback6,
  },
  {
    Name: "Інна, 33 роки:",
    Discriotion:
      '"Я дуже задоволена садком Inter-Kids. Донька стала більш творчою, вона постійно приносить додому свої малюнки та вироби. Вона стала більш відкритою і впевненою у собі. Вдячна вихователям за любов і турботу!"',
    Avatar: UserFeedback7,
  },
  {
    Name: "Олег, 42 роки:",
    Discriotion:
      '"Наш син у захваті від занять у садочку Inter-Kids. Він із задоволенням розповідає про нові ігри та друзів, яких він знайшов. Це дійсно місце, де дитина може розвиватися у всіх аспектах."',
    Avatar: UserFeedback8,
  },
  {
    Name: "Людмила, 41 рік:",
    Discriotion:
      '"Мій син завжди з нетерпінням чекає, коли піде в садок Inter-Kids. Він вивчає нові речі, весело проводить час і завжди повертається додому щасливим. Вихователі тут дійсно знають свою справу!"',
    Avatar: UserFeedback9,
  },
  {
    Name: "Богдан, 32 роки:",
    Discriotion:
      '"Inter-Kids - це місце, де моя донька навчилася бути впевненою у собі, спілкуватися з іншими дітьми та виявляти свої творчі здібності. Це чудовий садок з теплим та дружнім колективом."',
    Avatar: UserFeedback10,
  },
  {
    Name: "Тетяна, 34 роки:",
    Discriotion:
      '"Мій син обожнює садок Inter-Kids. Він навчений комунікаційним навичкам і став більш відкритим. Дуже вдячна вихователям за їхню підтримку та турботу!"',
    Avatar: UserFeedback11,
  },
  {
    Name: "Олександр, 33 роки:",
    Discriotion:
      '"Inter-Kids - це чудовий садок для нашої доньки. Вона дуже задоволена, завжди повертається додому з усмішкою та новими знаннями. Ми дуже вдячні всім, хто тут працює."',
    Avatar: UserFeedback12,
  },
  //{
  //  Name: "Аліна, 32 роки:",
  //  Discriotion:
  //    '"Садок Inter-Kids - це найкраще, що могло трапитися з нашою донькою. Вона стала більш соціальною, навчилася співпрацювати з іншими дітьми та розвивати свої навички."',
  //  Avatar: UserFeedback14,
  //},
  //{
  //  Name: "Сергій, 37 років:",
  //  Discriotion:
  //    '"Inter-Kids - це місце, де наш син почувається як вдома. Він став більш самостійним, впевненим у собі та соціально активним. Ми дуже задоволені цим садочком."',
  //  Avatar: UserFeedback15,
  //},
  //{
  //  Name: "Лариса, 36 років:",
  //  Discriotion:
  //    '"Садок Inter-Kids допоміг моїй доньці розкрити свої творчі здібності. Вона любить малювати, співати та брати участь у різних активностях. Це дійсно чудове місце для дітей!"',
  //  Avatar: UserFeedback16,
  //},
  //{
  //  Name: "Василь, 40 років:",
  //  Discriotion:
  //    '"Inter-Kids - це місце, де моя дитина навчилася взаємодіяти з іншими дітьми, розвивати свої творчі здібності та впевненість у собі. Дуже вдячний вихователям за їхню працю."',
  //  Avatar: UserFeedback17,
  //},
];
