import { React, useState } from "react";
import Button from "../button/button";
import CrosshairImf from "../image/modal-crosshair.png"
import './menu.scss';

function Menu({ PropsModal, PropsModalSet }) {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [city, setCity] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = document.createElement('form');
        form.action = 'https://docs.google.com/forms/d/e/1FAIpQLSeNdLoIk4pe-Qdl495p1L9pNHFYhy1GIQRUHPuAhf7uliX5Nw/formResponse';
        form.method = 'POST';
        form.target = 'hidden_iframe';

        const nameField = document.createElement('input');
        nameField.type = 'hidden';
        nameField.name = 'entry.512631380';
        nameField.value = name;
        form.appendChild(nameField);

        const phoneField = document.createElement('input');
        phoneField.type = 'hidden';
        phoneField.name = 'entry.361302695';
        phoneField.value = phone;
        form.appendChild(phoneField);

        const cityField = document.createElement('input');
        cityField.type = 'hidden';
        cityField.name = 'entry.510362471';
        cityField.value = city;
        form.appendChild(cityField);

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);

        alert('Ваші дані були відправлені!');
        setName('');
        setPhone('');
        setCity('');
        PropsModalSet(); // Закрыть модальное окно после отправки
    };

    return (
        <>
            <div className={`overlay ${PropsModal ? 'open' : ''}`}></div>
            <div className={`menu ${PropsModal ? 'open' : ''}`}>
                <div className="menu__content">
                    <span onClick={PropsModalSet} className='menu__close-modal'>
                        <img src={CrosshairImf} alt="" />
                    </span>
                    <p>
                        Встигніть записатися на безкоштовний урок!
                    </p>
                    <form className="form" onSubmit={handleSubmit}>
                        <input
                            type="text"
                            name="inp-name"
                            placeholder="Ім'я"
                            id="inp-name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <input
                            type="tel"
                            name="inp-tel"
                            placeholder="Номер телефону"
                            id="inp-tel"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                        <input
                            type="text"
                            name="inp-country"
                            placeholder="Питання"
                            id="inp-country"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                        />
                        <div className="menu__button-form">
                            <Button
                                classNameBtn={'menu-btn'}
                                textContentBtn={'Записатися в 1 клік'}
                            />
                        </div>
                    </form>
                </div>
            </div>
            <iframe name="hidden_iframe" style={{ display: 'none' }}></iframe>
        </>
    );
}

export default Menu;
