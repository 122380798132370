import React from "react";
import JobComponent  from "../../component/section-job/job";


function Job() {
    return (
        <JobComponent  />
    )
}

export default Job;